import React, { useContext } from "react";
import { GlobalContext } from "../contexts/GlobalContext";
import PageHeader from "../components/PageHeader";
import PlayerSearch from "../components/PlayerSearch";

import { faPeopleGroup } from "@fortawesome/free-solid-svg-icons";

function PlayersPage() {
  const { globalSeasonId, globalGameweek } = useContext(GlobalContext);

  return (
    <div>
      <PageHeader pageTitle="Players" icon={faPeopleGroup} />
      <PlayerSearch seasonId={globalSeasonId} gameweek={globalGameweek} />
    </div>
  );
}

export default PlayersPage;
