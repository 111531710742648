import React, {useContext} from "react";
import PageHeader from "../components/PageHeader";
import CurrentCupFixtures from "../components/cup/CurrentCupFixtures";
import { useNavigate } from "react-router-dom";

import { GlobalContext } from "../contexts/GlobalContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy } from "@fortawesome/free-solid-svg-icons";

function CurrentCupFixturesPage() {
    const { globalSeasonId, globalGameweek, globalActiveCup } =
    useContext(GlobalContext);
    const navigate = useNavigate();

    const handleTitleClick = () => {
        console.log("Here");
        
        navigate("/cup");
    };

    return (
        <div className="cup-fixtures">
            <PageHeader pageTitle="Cup Fixtures" icon={faTrophy} />

            <button
                onClick={() => handleTitleClick()}
                className="nav-button"
                >
                <FontAwesomeIcon icon={faTrophy} size="lg" />
                Tables
            </button>

            <CurrentCupFixtures seasonId={globalSeasonId} activeCup={globalActiveCup} gameweek={globalGameweek} />
            
        </div>
    )
}

export default CurrentCupFixturesPage;