import React from "react";
import PageHeader from "../components/PageHeader";
import Deadlines from "../components/Deadlines";

import { faClock } from "@fortawesome/free-solid-svg-icons";

function DeadlinesPage() {

    return (
        <div>
            <PageHeader pageTitle="Deadlines" icon={faClock} />
            <Deadlines />
        </div>
    )
}

export default DeadlinesPage;