import React, { useContext } from "react";
import LoginForm from "../components/LoginForm";
import PageHeader from "../components/PageHeader";

import { GlobalContext } from "../contexts/GlobalContext";

import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";

function Login() {
  const { globalSeasonId } = useContext(GlobalContext);

  return (
    <div>
      <PageHeader pageTitle="Login" icon={faRightToBracket} />
      <LoginForm seasonId={globalSeasonId} />
    </div>
  );
}

export default Login;
