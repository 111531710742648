import React from "react";
import PageHeader from "../components/PageHeader";
import LeagueAndCup from "../components/records/LeagueAndCup";

import { faAward } from "@fortawesome/free-solid-svg-icons";

function Records() {
  return (
    <div>
      <PageHeader pageTitle="Records" icon={faAward} />
      <LeagueAndCup />
    </div>
  );
}

export default Records;
