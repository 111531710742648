import React, { useEffect } from "react";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/client";

function CurrentCupFixtures(props) {
  const { seasonId, activeCup, gameweek } = props;

  const FETCH_CUP_FIXTURES = gql`
    query ($seasonId: String!, $activeCup: Int!) {
      getCupFixtures(seasonId: $seasonId, activeCup: $activeCup) {
        _id
        GroupName
        Fixtures {
          Gameweek
          Week
          Matches {
            MatchNumber
            Team1 {
              TeamName
              Points
              Bye
            }
            Team2 {
              TeamName
              Points
              Bye
            }
          }
        }
      }
    }
  `;

  const [
    loadCupFixturesData,
    { loading, data: { getCupFixtures: cupFixtures } = {} },
  ] = useLazyQuery(FETCH_CUP_FIXTURES, {
    variables: { seasonId, activeCup },
  });

  useEffect(() => {
    if (seasonId && activeCup && gameweek) {
      loadCupFixturesData();
    }
  }, [seasonId, activeCup, gameweek]);

  return (
    <div>
      {loading ? (
        <div>Loading cup tables...</div>
      ) : (
        cupFixtures ? (
        cupFixtures
          .slice()
          .sort((a, b) => a.GroupName - b.GroupName)
          .map((ckf) => {
            return (
                <table className="cup-knockout-fixtures" key={ckf.GroupName}>
                    <tbody>
                    {
                    ckf && ckf.Fixtures
                    .filter((fixture) => fixture.Gameweek === gameweek)
                    .map((fixture) => {
                        return (
                            fixture && fixture.Matches
                            .map((match, index) => {
                                return (
                                    <tr className="cup-knockout-fixtures-row" key={index}>
                                        <td width="35%">{match.Team1.TeamName}</td>
                                        <td width="10%">{match.Team1.Points}</td>
                                        <td width="10%">vs</td>
                                        <td width="10%">{match.Team2.Points}</td>
                                        <td width="35%">{match.Team2.TeamName}</td>
                                    </tr>
                                );
                            })
                            
                        );
                    })
                    }
                    </tbody>
                </table>
            );
          })
        ) : (
            <div>No cup fixtures this week</div>
        )
      )}
    </div>
  );
}

export default CurrentCupFixtures;
