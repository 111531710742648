import React, { useContext } from "react";
import Gameweeks from "../components/Gameweeks";

import { GlobalContext } from "../contexts/GlobalContext";
import PageHeader from "../components/PageHeader";

import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";

function Lineups() {
  const { globalSeasonId, globalGameweek } = useContext(GlobalContext);

  return (
    <div>
      <PageHeader pageTitle="Lineups" gameweek={globalGameweek + 1} icon={faCalendarDays} />
      <Gameweeks seasonId={globalSeasonId} gameweek={globalGameweek + 1} />
    </div>
  );
}

export default Lineups;
