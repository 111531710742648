import React from "react";
import Logout from "../components/Logout";
import PageHeader from "../components/PageHeader";

import { faGear } from "@fortawesome/free-solid-svg-icons";

function Settings() {
  return (
    <div>
      <PageHeader pageTitle="Settings" icon={faGear} />
      <Logout />
    </div>
  );
}

export default Settings;
