import React from "react";
import TablePage from "./TablePage";
import DeadlinesPage from "./DeadlinesPage";
import CurrentCupFixturesPage from "./CurrentCupFixturesPage";

function Home() {
  return (
    <React.Fragment>
      <div className="home-widget">
        <TablePage />
      </div>

      <div className="home-widget">
        <DeadlinesPage />
      </div>

      <div className="home-widget">
        <CurrentCupFixturesPage />
      </div>
    </React.Fragment>
  );
}

export default Home;
